<template>
    <div class="sample-memo">
        <h1 class="h0">
            What is a Memo?
        </h1>
        <p>Memos are knowledge or insights shared 10 short bullet points at a time. Here is an example of a Memo:</p>
        <figure>
            <img
                src="@assets/img/founders-landing/sample-memo/sample-memo-mobile.png"
                srcset="@assets/img/founders-landing/sample-memo/sample-memo-mobile.png 1x, @assets/img/founders-landing/sample-memo/sample-memo-mobile@2x.png 2x"
                class="img-fluid d-block d-lg-none margin-auto"
                alt="Sample Memo"
            >
            <img
                src="@assets/img/founders-landing/sample-memo/sample-memo.png"
                srcset="@assets/img/founders-landing/sample-memo/sample-memo.png 1x, @assets/img/founders-landing/sample-memo/sample-memo@2x.png 2x"
                class="img-fluid d-none d-lg-block"
                alt="Sample Memo"
            >
        </figure>
    </div>
</template>

<script>
export default {
    name: "SampleMemo"
}
</script>

<style lang="scss" scoped>
.sample-memo {
    padding: 50px 0;
    padding-bottom: 100px;

    h1 {
        margin-bottom: 15px;
        text-align: center;
        color: $primary-color;

        @media(max-width: $xl) {
            text-align: center;
        }

        @media(max-width: $sm) {
            font-size: 24px;
            line-height: 50px;
        }
    }

    p {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 27px;
        font-weight: 200;
        text-align: center;
        margin-bottom: 40px;

        @media(max-width: $lg) {
            text-align: center;
        }

        @media(max-width: $sm) {
            font-size: 18px;
            line-height: 20px;
        }
    }

    figure {
        position: relative;
        margin-bottom: 0;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 40%;
            background: rgb(31,31,31);
            background: linear-gradient(0deg, #181818 0%, rgba(31, 31, 31, 0) 100%);
            bottom: 0;
            left: 0;
            right: 0;

            @media(max-width: $lg) {
                background: linear-gradient(0deg, #181818 0%, rgba(31, 31, 31, 0) 100%);
            }
        }

        img {
            margin: 0 auto;
        }
    }
}
</style>
